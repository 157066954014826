import React, { useEffect, useState } from 'react'
import { Container, Spinner, Table } from 'react-bootstrap'
import { fetchTransactions } from '../Constant/ApiCalling';
import moment from 'moment/moment';
import Loader from './Loader';
import { NotificationTypes, showNotification } from '../Constant/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function Transactions() {
    const [transactions, setTransactions] = useState([]);
    const [loading, setLoading] = useState(true);
  // Fetches transactions on component mount and updates state; stops loading after completion.
    useEffect(() => {
      const getTransactions = async () => {
        try {
          const data = await fetchTransactions()
          setTransactions(data?.reverse() || []); 
        } catch (error) {
        } finally {
          setLoading(false);
        }
      };
  
      getTransactions();
    }, []);
    /**
 * Copies the provided text to the clipboard and shows a notification.
 * 
 * @param {string} text - The text to be copied.
 * @param {string} label - A label to display in the notification (e.g., "Transaction Hash", "User Address").
 */
    const handleCopy = async (text, label) => {
      if (!text) return;
      try {
        await navigator.clipboard.writeText(text);
        showNotification(`${label} copied to clipboard!`, NotificationTypes.SUCCESS);
      } catch (error) {
        showNotification(`Failed to copy ${label}!`, NotificationTypes.ERROR);
      }
    };
    
    

  return (
    <main className="inner-main-faqs">
  <div className="full-div-tabel">
    <div className="card-tabel">
      <Container>
        <h4>Latest Transactions</h4>
        
        <Table bordered responsive>
          <thead>
            <tr>
              <th>Transaction Hash</th>
             
              <th>Amount</th>
               <th>User Address</th>
              <th>From Currency</th>
              <th>To Currency</th>
              <th>Completed At</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="8" className="text-center py-4">
                  <Loader animation="border" />
                </td>
              </tr>
            ) : transactions?.length > 0 ? (
              transactions?.map((txn, index) => (
                <tr key={index}>
<td className="copyable-text">
  {txn.transactionHash.slice(0, 15)}...{txn.transactionHash.slice(-15)}
  <FontAwesomeIcon 
    icon={faCopy} 
    className="copy-icon" 
    onClick={() => handleCopy(txn.transactionHash, "Transaction Hash")} 
  />
</td>



                  <td>{txn.amount}</td>
                  <td className="copyable-text">
  {txn.userAddress.slice(0, 10)}...{txn.userAddress.slice(-10)}
  <FontAwesomeIcon 
    icon={faCopy} 
    className="copy-icon" 
    onClick={() => handleCopy(txn.userAddress, "User Address")} 
  />
</td>
                  <td>{txn.fromCurrency}</td>
                  <td>{txn.toCurrency}</td>
                 
                  <td>
     { moment.utc(txn.completedAt).local().format("YYYY-MM-DD")}

                  </td>
                  </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8" className="text-center">No transactions found</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Container>
    </div>
  </div>
</main>

  )
}

export default Transactions
