import Web3 from "web3";
import { tokenContractAbi } from "./TokenContractAbi";
import { NotificationTypes, showNotification } from "../Constant/Alert";
import { tokenContractAddress } from "../Constant/Constant";

export const RPC_detail = {
    chainId: "0xa33",
    chainName: "RLC-Mainet",
    rpcUrls: [""],
    blockExplorerUrls: ["https://dataseed2.redlightscan.finance/"],
    nativeCurrency: {
      symbol: 'RLC',
      decimals: 18
    }
   
  };
  export const ChainIds = 137
  
  export const getTokenBalance =async (address, walletaddress) => {
    const web3 = new Web3(window.ethereum);
    await    detectAndSwitchToPolygon()
    const contract = new web3.eth.Contract(tokenContractAbi, address);
    return new Promise((resolve, reject) => {
      contract.methods
        .balanceOf(walletaddress)
        .call()
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const detectAndSwitchToPolygon = async () => {
    if (!window.ethereum) {
        throw new Error("MetaMask not found!");
    }

    const web3 = new Web3(window.ethereum);
    const currentChainId = await web3.eth.getChainId();
    if (currentChainId !== ChainIds) { 
        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: "0x89" }] 
            });
        } catch (switchError) {
            throw switchError; 
        }
    } else {
    }
};

 
  export const projectId = 'db374e4debdaa59545c0b24f71f868a8';




export function formatAmount(amount) {
  if (amount === undefined || amount === null) {
        return ''; 
    }
  // Convert amount to string
  let amountString = amount.toString();
    
  // Separate the integer part and the fractional part
  let parts = amountString.split('.');
  let integerPart = parts[0];
  let fractionalPart = parts[1] || '';

  // Limit the integer part to 9 digits
  integerPart = integerPart.substring(0, 9);

  // Check if fractional part is non-zero
  if (fractionalPart.length > 0 && parseInt(fractionalPart) !== 0) {
      // Limit the fractional part to 3 digits
      fractionalPart = fractionalPart.substring(0, 3);
      // Pad fractional part with zeros if it has fewer than 3 digits
      fractionalPart = fractionalPart.padEnd(3, '0');
      // Include fractional part only if it's non-zero
      return `${integerPart}.${fractionalPart}`;
  } else {
      // If fractional part is zero or empty, return integer part only
      return integerPart;
  }
}

     /**
 * Function to add a custom ERC-20 token to the user's MetaMask wallet.
 * It uses the `wallet_watchAsset` method provided by MetaMask.
 *
 * - If MetaMask (window.ethereum) is available, it requests the user to add the token.
 * - The token details (address, symbol, decimals, and image) are provided in the request.
 * - If MetaMask is not detected, a notification is shown to inform the user.
 *
 * @async
 * @function addTokenToMetamask
 */
   export  const addTokenToMetamask = async () => {
      try {
        if (window.ethereum) {
          await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20",
              options: {
                address: tokenContractAddress, 
                symbol: "T1",
                decimals: 18, 
                image: "TOKEN_IMAGE_URL", 
              },
            },
          });
        } else {
          showNotification("MetaMask or Ethereum not detected.",NotificationTypes.ERROR);
        }
      } catch (error) {
      }
    };


    export const faqData = [
      {
        question: "What is the 1CM Coin DApp?",
        answer:
          "The 1CM Coin DApp is a decentralized application that allows users to:\n" +
          "- Swap Offline 1CM Tokens (loyalty points) → Online 1CM Tokens (Polygon-based)\n" +
          "- Swap Online 1CM Tokens (on Polygon) → Offline 1CM Tokens (for in-store/app use)\n" +
          "This enables users to seamlessly swap web3 blockchain tokens and traditional loyalty rewards.",
      },
      {
        question: "Why should I swap between Offline & Online tokens?",
        answer:
          "Swap Offline → Online (Web3 Use Cases)\n" +
          "- Store tokens in a crypto wallet (MetaMask, Phantom, etc.)\n" +
          "- Trade tokens on Uniswap and PancakeSwap\n" +
          "- Use them for NFT purchases and future Web3 utilities\n\n" +
          "Swap Online → Offline (In-Store Use Cases)\n" +
          "- Convert blockchain 1CM tokens back into loyalty points\n" +
          "- Redeem for discounts, products, or exclusive perks at 1CM store and app",
      },
      {
        question: "What is the swap rate?",
        answer:
          "The swap rate is always 1:1. For every 1 offline 1CM token, you will receive 1 online 1CM token, and vice versa.",
      },
      {
        question: "How do I log in to the 1CM Coin DApp?",
        answer:
          "Enter your 1CM mobile app credentials (same as the mobile app login).\n" +
          "After logging in, your offline and online token balances will be displayed.",
      },
      {
        question: "How do I swap my Offline tokens to Online tokens?",
        answer:
          "1. Connect your crypto wallet (e.g., MetaMask, Phantom).\n" +
          "2. Choose 'Swap Offline → Online'.\n" +
          "3. Enter the number of offline tokens to swap.\n" +
          "4. Confirm the transaction.\n" +
          "5. Your 1CM online tokens will be sent to your wallet.",
      },
      {
        question: "How do I swap my Online tokens to Offline tokens?",
        answer:
          "1. Connect your crypto wallet (e.g., MetaMask, Phantom).\n" +
          "2. Choose 'Swap Online → Offline'.\n" +
          "3. Enter the number of online tokens to swap.\n" +
          "4. Confirm the transaction.\n" +
          "5. Your offline balance in the 1CM mobile app will be updated.",
      },
      {
        question: "Where will my swapped tokens go?",
        answer:
          "- Online 1CM tokens go to your crypto wallet.\n" +
          "- Offline 1CM tokens go back to your 1CM app balance (for in-store use).",
      },
      {
        question: "Which wallets can I use?",
        answer:
          "Any Polygon-compatible wallet, such as:\n" +
          "- MetaMask\n" +
          "- Phantom\n" +
          "- Trust Wallet\n" +
          "- Coinbase Wallet\n" +
          "- WalletConnect-supported wallets",
      },
      {
        question: "Can I trade 1CM tokens on Uniswap?",
        answer: "Yes! You can buy, sell, or swap 1CM online tokens on Uniswap or other Polygon DEXs.",
      },
      {
        question: "How do I add 1CM tokens to Uniswap?",
        answer:
          "1. Open Uniswap and select the Polygon network.\n" +
          "2. Paste the 1CM token contract address to add it.\n" +
          "3. Swap 1CM tokens for other cryptocurrencies.",
      },
      {
        question: "Are there any fees for swapping?",
        answer:
          "Offline → Online Swap: No fee (Master wallet will cover the transaction fee).\n" +
          "Online → Offline Swap: Gas fee applies (user will cover the gas fee).",
      },
      {
        question: "How much MATIC do I need for gas fees?",
        answer:
          "Since it’s on Polygon, gas fees are very low—usually around $0.01–$0.10 in MATIC.",
      },
      {
        question: "Is my swap transaction recorded?",
        answer:
          "Yes, all Online token swaps are recorded on the Polygon blockchain for transparency and security.",
      },
      {
        question: "What happens if I lose my wallet?",
        answer:
          "If you lose access to your wallet, your online 1CM tokens are lost unless you have your seed phrase backed up.\n" +
          "Offline tokens are safe since they remain in the 1CM app.",
      },
      {
        question: "I can’t log in to the 1CM Coin DApp. What should I do?",
        answer:
          "1. Check your login credentials (same as the 1CM mobile app).\n" +
          "2. Reset your password if needed.\n" +
          "3. Clear your browser cache and try again.",
      },
      {
        question: "My wallet isn’t connecting. What should I do?",
        answer:
          "1. Ensure you are using a supported wallet (MetaMask, Phantom, etc.).\n" +
          "2. Make sure your wallet is set to the Polygon network.",
      },
      {
        question: "My swap transaction is stuck or pending for too long.",
        answer:
          "1. Check PolygonScan to see the transaction status.\n" +
          "2. If it’s stuck, increase the gas fee and try again.",
      },
      {
        question: "Who do I contact for support?",
        answer: "Email us at 1cmcoin@1cminc.com for assistance.",
      },
    ];
    