import React, { createContext, useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { Container, Nav, Navbar, Button, Form } from "react-bootstrap";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../Assets/image/logo.png";
import cross from "../Assets/image/Vector.png";
import bars from "../Assets/image/Group_1029.png";
import { useContextDetails } from "../Constant/AppContext";
import { NotificationTypes, showNotification } from "../Constant/Alert";
import img from "../Assets/image/avatar.png";
import { ChainIds, formatAmount } from "../Web3/Constant";
import {
  ChainId,
  ConnectWallet,
  useAddress,
  useChainId,
  useDisconnect,
  useNetwork,
  useNetworkMismatch,
} from "@thirdweb-dev/react";
import coin1 from "../Assets/image/coin-2.png";
import { DocFile } from "../Constant/Constant";

function NavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const contextData = useContextDetails();
  const address = useAddress();
  const disconnect = useDisconnect();
  const chainId = useChainId();
  const isWrongBlockcahin = useNetworkMismatch();
  const [, switchNetwork] = useNetwork();
  const [collapseOpen, setCollapseOpen] = useState(false);
  var path = useLocation().pathname;
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    navigate("/login");
  };
  const disconnectWallet = () => {
    localStorage.removeItem("thirdweb_wallet"); 
    localStorage.removeItem("connectedWallets");
    localStorage.removeItem("walletconnect"); 
    sessionStorage.clear();
    disconnect();
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(address);
      showNotification(
        "Address copied to clipboard!",
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      showNotification("Failed to copy to clipboard", NotificationTypes.ERROR);
    }
  };
 
  useEffect(() => {
    if (address) {
    }
  }, [address]);
  useEffect(() => {
    if (isWrongBlockcahin) {
      switchNetwork(ChainIds);
    }
  }, [address, isWrongBlockcahin, switchNetwork]);

  const handleNavItemClick = () => {
    setCollapseOpen(false);
  };

  useEffect(() => {
    setCollapseOpen(false);
  }, [location.pathname]);

  const handleCopyEmail = async() => {
    if (!contextData?.userInfo?.email) return;
    try {
    await  navigator.clipboard.writeText(contextData?.userInfo?.email)

    showNotification("Email copied to clipboard!",NotificationTypes.SUCCESS )
      
  }catch(error) {
        showNotification("Failed to copy email!", NotificationTypes.ERROR);
      };
  };
  const onButtonClick = () => {
    const pdfUrl = "whitepaper.pdf";

    // Download the PDF
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = DocFile;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Open the PDF in a new tab
    window.open(pdfUrl, "_blank");
  };

  return (
    <>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container className="p-0" fluid>
          <Navbar.Brand as={Link} to="/">
            <img src={logo} className="logo" />
          </Navbar.Brand>
          <div
        
          >
            <div id="user-pr-pnl">
              <Button className="blnce-btn">
                Balance: <span>{contextData?.IsBalance}</span>
              </Button>
              <NavDropdown
                title={
                  <div className="user-pf-pnl d-flex align-items-center">
                    <div className="img-pnl">
                      <img
                        src={
                          contextData?.userInfo?.profile&&contextData?.userInfo?.profile.trim() !== ""
                            ? contextData?.userInfo?.profile
                            : img
                        }
                        className="profile"
                      />
                    </div>
                    <div className="txt-pnl">
                      <h6 onClick={(e) => e.stopPropagation()}>
                        {contextData?.userInfo?.name
                          ? contextData?.userInfo?.name
                           
                          : "DefaultName"}
                      </h6>
                      <p  onClick={
    handleCopyEmail
  }>
                        
                        {" "}
                        {contextData?.userInfo?.email
                          ? `@${
                              contextData?.userInfo?.email?.slice(0, 3) +
                              ".." +
                              contextData?.userInfo?.email
                                ?.slice(-4)
                                .split("@")[0]
                            }`
                          : "@DefaultName"}
                      </p>
                    </div>
                  </div>
                }
                id="UserDropdown"
              >
                <NavDropdown.Item className="bnce-btn mobile" as={Link} to="#">
                <span className="balacebtn">  Offline:{""}   <img className="coinimg" src={coin1} alt="coin" />{" "} <span  className="balancebtn">{contextData?.IsBalance}</span> </span>   
                </NavDropdown.Item>
                {contextData &&
                  contextData?.onlinebalace !== null &&
                  contextData?.onlinebalace !== "" && (
                    <NavDropdown.Item
                      className="bnce-btn mobile"
                      as={Link}
                      to="#"
                    >
                   <span className="balacebtn">   Online:{" "}   <img className="coinimg" src={coin1} alt="coin" />{" "}
                      <span  className="balancebtn">{formatAmount(contextData?.onlinebalace)}</span></span>
                    </NavDropdown.Item>
                  )}
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
              <div className="removebtn">
                {address && chainId == ChainIds ? (
                  <>
                    <Button onClick={copyToClipboard}>
                      {address?.slice(0, 5) + "..." + address?.slice(-4)}
                    </Button>
                    <Button className="connect-btn ml-2" onClick={disconnectWallet}>
                      Disconnect
                    </Button>
                  </>
                ) : (
                  <ConnectWallet
                    className="connect-btn"
                    btnTitle={"Connect"}
                    switchToActiveChain={true}
                    modalSize={"compact"}
                  />
                )}
              </div>
            </div>
            <div className="collaspbtn"
              onClick={() => {
                setCollapseOpen(!collapseOpen);
              }}
            >
              {collapseOpen ? < img src={cross} className="cross" /> :   <img src={bars}  className="Bars"/>}
            </div>
           
          </div>
          <Navbar.Collapse
            id="navbarScroll"
            in={collapseOpen}
          >
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                as={Link}
                to="/CoinSwap"
                className={`${path === "/CoinSwap" ? "active" : ""}`}
                onClick={handleNavItemClick}
              >
                Coin Swap
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/UniSwap"
                className={`${path === "/UniSwap" ? "active" : ""}`}
                onClick={handleNavItemClick}
              >
                Uni Swap
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/PurchaseGift"
                className={`${
                  path === "/PurchaseGift" || path === "/CardDetails"
                    ? "active"
                    : ""
                }`}
                onClick={handleNavItemClick}
              >
                Purchase Gift Card
              </Nav.Link>
              <Nav.Link
                as={Link}
                to="/SendGiftCard"
                className={`${path === "/SendGiftCard" ? "active" : ""}`}
                onClick={handleNavItemClick}
              >
                Send Gift Card
              </Nav.Link>

              <Nav.Link onClick={onButtonClick}>Whitepaper</Nav.Link>
              <Nav.Link   as={Link}
                to="/FrequentlyAskedQuestions"
                className={`${path === "/FrequentlyAskedQuestions" ? "active" : ""}`}
                onClick={handleNavItemClick}
              > FAQs 
              </Nav.Link>



              <Nav.Link>
                <div className="showbtn">
                  {address && chainId == ChainIds ? (
                    <>
                      <Button onClick={copyToClipboard}>
                        {address?.slice(0, 5) + "..." + address?.slice(-4)}
                      </Button>
                      <Button className="connect-btn ml-2" onClick={disconnectWallet}>
                        Disconnect
                      </Button>
                    </>
                  ) : (
                    <ConnectWallet
                      className="connect-btn"
                      btnTitle={"Connect"}
                      switchToActiveChain={true}
                      modalSize={"compact"}
                    />
                  )}
                </div>
              </Nav.Link>
            </Nav>
            <div id="user-pr-pnl">
              <NavDropdown
                title={
                  <div className="user-pf-pnl d-flex align-items-center">
                    <div className="img-pnl">
                      <img
                        src={
                          contextData?.userInfo?.profile&& contextData?.userInfo?.profile.trim() !== ""
                            ? contextData?.userInfo?.profile
                            : img
                        }
                        className="profile"
                      />
                    </div>
                    <div className="txt-pnl">
                      <h6 onClick={(e) => e.stopPropagation()}>
                        {contextData?.userInfo?.name
                          ? contextData?.userInfo?.name
                              
                          : "DefaultName"}
                      </h6>

                      <p  onClick= {
  
    handleCopyEmail
  }>
                        {" "}
                        {contextData?.userInfo?.email
                          ? `${contextData?.userInfo?.email}`
                          : "@DefaultName"}
                      </p>
                    </div>
                  </div>
                }
                id="UserDropdown"
              >
                <NavDropdown.Item as={Link} className="bnce-bt">
                  <div className="small-img-pnl">
                    <span className="balacebtn">
                      Offline:{" "}
                      <img className="coinimg" src={coin1} alt="coin" />{" "}
                      <span className="balancebtn">
                        {contextData?.IsBalance}
                      </span>
                    </span>
                  </div>
                </NavDropdown.Item>
                {contextData &&
                  contextData?.onlinebalace !== null &&
                  contextData?.onlinebalace !== "" && (
                    <NavDropdown.Item as={Link}>
                      <div className="small-img-pnl">
                        <span className="balacebtn">
                          Online:{" "}
                          <img className="coinimg" src={coin1} alt="coin" />{" "}
                          <span className="balancebtn">
                            {formatAmount(contextData?.onlinebalace)}
                          </span>{" "}
                        </span>
                      </div>
                    </NavDropdown.Item>
                  )}
                <NavDropdown.Item onClick={handleLogout}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>

              {/* <Button className='connect-btn'>Connect</Button> */}

              {address && chainId == ChainIds ? (
                <>
                  <Button onClick={copyToClipboard}>
                    {address?.slice(0, 5) + "..." + address?.slice(-4)}
                  </Button>
                  <Button className="connect-btn ml-2" onClick={disconnect}>
                    Disconnect
                  </Button>
                </>
              ) : (
                <>
                  <ConnectWallet
                    className="connect-btn"
                    btnTitle={"Connect"}
                    modalSize={"compact"}
                    switchToActiveChain={true}
                  />
                </>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavBar;
