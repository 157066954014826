import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Row, Container, Modal, Form } from "react-bootstrap";
import { faqData } from "../Web3/Constant";
function FrequentlyAskedQuestions() {

  const [openIndex, setOpenIndex] = useState(null);

  const toggleCollapse = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
 
  

  return (
    <>
     
     <main className="inner-main-faqs">
      
  <Container fluid>
    <Row>
      <Container>
        <Row>
          <Col xl="12" lg="12" md="12" sm="12" xs="12">
            {/* Centered Heading */}
            <h1 className="faq-heading">Frequently Asked Questions</h1>

              <div className="faq-container">
                {faqData?.map((item, index) => (
                  <div key={index} className="faq-item">
                    <button onClick={() => toggleCollapse(index)} className="faq-button">
                      <span>{item.question}</span>
                      <svg
                        className={`faq-icon ${openIndex === index ? "open" : ""}`}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    <div className={`faq-content ${openIndex === index ? "open" : ""}`}>
                    <p>
                    {item.answer.split("\n").map((line, i) => (
  <React.Fragment key={i}>
    {line.split(" ").map((word, j) =>
      word.includes("@") ? (
        <a key={j} href={`mailto:${word}`} className="email-link">
          {word}
        </a>
      ) : (
        <React.Fragment key={j}> {word} </React.Fragment>
      )
    )}
    <br />
  </React.Fragment>
))}


              </p>                    </div>
                  </div>
                ))}
              </div>
          </Col>
        </Row>
      </Container>
    </Row>
  </Container>
</main>

    
     
    </>
  );
}
export default FrequentlyAskedQuestions;
