import axios from 'axios';
import setAuthorizationToken, { API_URLS, clientId, clientSecret, instance, masterwallect, tokenContractAddress } from '../Constant/Constant';
import coin1 from '../Assets/logo.png';
const Base_Url="https://test-apigateway.payfirma.com/transaction-service"
// const Base_Url2="https://sandbox-apigateway.payfirma.com"
const auth = "dGFsaGEuZGV2QGFtbWFnLnRlY2g6VGFsaGFANDcyMg=="
const Base_Url2="https://test-apigateway.payfirma.com"
const base="https://sandbox-auth.payfirma.com"
const base2=  "https://sandbox-apigateway.payfirma.com"
// const JwtToken="eyJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NfdG9rZW4iOiI5ZTViZTBlYS0xYzdhLTQyMTctYWZhZS0yNzg5NDdmYzMxNzEiLCJzY29wZSI6WyJ0cmFuc2FjdGlvbiJdLCJtZXJjaGFudF9pZCI6IjY5MTZhMjY2MmIiLCJleHAiOjE5OTAzMjk5MzZ9.Qe4GklWzVBpN2I67GkFuOXNW5MKeSIw7acMuc2DNsWw"
const JwtToken="eyJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NfdG9rZW4iOiJiZTVjNjNhZC1mNDlmLTQ5OTctOWI5OC0xZjBlNmRhY2E5MzIiLCJzY29wZSI6WyJ0cmFuc2FjdGlvbiJdLCJtZXJjaGFudF9pZCI6IjI5NTIwOTg2ZjkiLCJleHAiOjE5NTQ0MTY5MjJ9.5yUx07A26jBI_q2U2BtcC1bXbsBc1bYHhGjwAmbLj3o"
// const JwtToken="eyJhbGciOiJIUzI1NiJ9.eyJhY2Nlc3NfdG9rZW4iOiIxNDYwNTM2NC04ZWYzLTQ0MDItYThiYy0zYTlhMzRkNTE1ZGMiLCJzY29wZSI6WyJhbmFseXRpY3MiLCJjdXN0b21lciIsImZpbGUiLCJpbnZvaWNlIiwibWVyY2hhbnQiLCJwYXJ0bmVyIiwicGxhbiIsInRyYW5zYWN0aW9uIl0sIm1lcmNoYW50X2lkIjoiOWIyMGNjMjA4MiIsImV4cCI6MTcwNTk5MzI5M30.O8nlPv-0g3wMAgVyYzdXJOG8qTTPL2xEi-Uo67-XBS4"
const makeApiCall = async (url, method, data) => {
  try {
    setAuthorizationToken();

    const response = await instance({
      method,
      url,
      data,
    });

    return response.data;
  } catch (error) {
    throw error;
  }
};
export const postOffRampTransaction = async (requestData) => {
  try{
    const response = await makeApiCall("api/Swap/OffRampTransactionsV1", "post", requestData);
    return response;
  } catch (error) {
    throw error;
  }
  
  }
   

export const fetchWalletBalance = async () => {
  try {
    const response = await makeApiCall('/api/Admin/GetWalletBalance', 'get', null);
    return parseFloat(response?.data);
  } catch (error) {
    console.error('Error fetching wallet balance:', error);
    throw error;
  }
};
/**
 * Function to fetch user transactions from the API.
 * 
 * This function makes an asynchronous GET request to the endpoint '/api/Admin/GetUsersTransactions'
 * to retrieve the latest user transactions. It handles errors gracefully by logging them to the console.
 *
 * @returns {Promise<Array>} A promise that resolves to the transaction data if successful.
 * @throws {Error} Throws an error if the API request fails.
 */

// fetchTransactions.js
export const fetchTransactions = async (pageSize, currentPage) => {
  try {
    const response = await makeApiCall(`/api/Admin/GetUsersTransactions?PageSize=${pageSize}&CurrentPage=${currentPage}`, 'get', null);
    return response;
  } catch (error) {
    console.error("Error fetching transactions:", error);
    return { data: [], totalItems: 0 };
  }
};


export const fetchExchangeRate = async (amount) => {
  try {
    const response = await makeApiCall(`/api/Gift/GetCanadianDollerBy1CMToken?tokenAmount=${amount}`, 'get', null);
    return response;
  } catch (error) {
    console.error('Error fetching exchange rate:', error);
    throw error;
  }
};
export const fetchGifts = async (pageSize, currentPage) => {
    try {
      const response = await makeApiCall(`api/Gift/GetAllGiftByAccountID?PageSize=${pageSize}&CurrentPage=${currentPage}`, 'get', null);
      return response;

    } catch (error) {
      console.error('Error fetching gifts:', error);
      throw error;
    }
  };

export const postGiftPurchase = async (requestData) => {
  try {
    const formData = new FormData();
    for (const key in requestData) {
      formData.append(key, requestData[key]);
    }

    const response = await makeApiCall('api/Gift/AddAndBuyGiftsOfflineRamp', 'post', formData);
    return response;
  } catch (error) {
    console.error('Error posting gift purchase:', error);
    throw error;
  }
};

export const postOnlineGiftPurchase = async (transationHash, requestData) => {
    try {
      const formData = new FormData();
      for (const key in requestData) {
        formData.append(key, requestData[key]);
      }
  
      const response = await makeApiCall(`api/Gift/AddAndBuyGiftsOnlineRamp?TxHash=${transationHash}`, 'post', formData);
      return response;
    } catch (error) {
      console.error('Error posting online gift purchase:', error);
      throw error;
    }
  };
  export const GiftTransfer = async (giftid, email) => {
    try {
      
  
      const response = await makeApiCall(`api/Gift/TransferGift?giftId=${giftid}&email=${email}`, 'post');
      return response;
    } catch (error) {
      console.error('Error posting online gift purchase:', error);
      throw error;
    }
  };


  export const GetTransationGenrate = async (type,token) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post(`transaction-service/sale `, type,{
          baseURL: base,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', 
          },
        });
        resolve(response?.data);
        
      } catch (error) {
        reject(error);
      }
    });
  };



  export const GetToken = async (requestData) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
      for (const key in requestData) {
        formData.append(key, requestData[key]);
      }

     const combinedString = `${clientId}:${clientSecret}`;
      
      const basicToken = btoa(combinedString);
    
        // const response = await axios.post(`/transaction-service-vt/sale`, type,{
          const response = await axios.post(`/oauth/token`, formData,{

          baseURL: base,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': `Basic ${basicToken}`
          }
         
        });
        resolve(response?.data);
        
      } catch (error) {
        reject(error);
      }
    });
  };

  export const GetSaleToken = async (type,token) => {
    return new Promise(async (resolve, reject) => {
      try {
    
        const response = await axios.post(`/transaction-service-vt/sale`, type,{

          baseURL: base2,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json', 
          },
  
        });
        resolve(response?.data);
        
      } catch (error) {
        reject(error);
      }
    });
  };

  export const GetBuyGiftsBankCard = async (requestData) => {
    try {
      
      const formData = new FormData();
    for (const key in requestData) {
      formData.append(key, requestData[key]);
    }

      const response = await makeApiCall(`api/Gift/AddAndBuyGiftsBankCard`, 'post',formData);
      return response;
    } catch (error) {
      console.error('Error posting online gift purchase:', error);
      throw error;
    }
  };
   export async function sendNotificationByGlUserID(title, gluserId, DescriptionMessage, Route) {
    if (!gluserId) {
        return;
    }

    const url = `${API_URLS.SEND_NOTIFICATION}?GlUserID=${gluserId}`;

    const bodyFormData = new FormData();
    bodyFormData.append("Tittle", title);
    bodyFormData.append("Description", DescriptionMessage);
    bodyFormData.append("Route", Route);
        const response = await fetch(coin1);
        const blob = await response.blob();
        const file = new File([blob], "default-logo.png", { type: blob.type });
        bodyFormData.append("Image", file); 

    try {
        
      await axios.post(url, bodyFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });

    } catch (error) {
    }
}
