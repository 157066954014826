import axios from "axios";
import { ChainId, RPC_detail, chainId } from "../Web3/Constant";

let token = sessionStorage.getItem("JsonWebToken");
 let baseURL="https://onecm.ammag.tech"
//  https://onecm-coin-dapp-be.onecannabismarketinc.com
// let baseURL = "http://103.125.70.205:8648";
const setAuthorizationToken = () => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

const instance = axios.create({
  baseURL:baseURL,
});
instance.interceptors.request.use(
  (config) => {
    config.headers['Authorization'] =`Bearer ${sessionStorage.getItem("JsonWebToken")}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default setAuthorizationToken;
export {instance}

export const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const isValidPhone = (phone) => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phone);
};


export const passwordRegex = {
  minLength: 8,
  specialChar: /[!@#$%^&*(),.?":{}|<>]/,
  number: /\d/,
  capital: /[A-Z]/,
  lower: /[a-z]/,
};
export const myRegex = /^(?=.*[!@#$%^&*(),.?":{}|<>])(?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,}$/;
export const JSON_RPC_URL = {
  // 1: ['https://dataseed2.redlightscan.finance/'],
  // 3: ['https://dataseed2.redlightscan.finance/']
  1: ['https://polygon.drpc.org/'],
  3: ['https://polygon.drpc.org/']
}
export const theme = {
  primary: '#000',
  secondary: '#666',
  interactive: '#AC7AF7',
  container: '#FFF',
  module: '#E7E7E7',
  accent: '#AC7AF7',
  outline: '#AC7AF7',
  dialog: '#FFF',
  fontFamily: 'Raleway, sans-serif',
  borderRadius: 0.8,
}
export const wallletaddressregex=/^0x[0-9a-fA-F]{40}$/;
export const tokenContractAddress = "0x12A77096C9138911Aa839b1D9Bc1AEf500534Ba2";
// export const tokenContractAddress = "0x6593a87AaBf1E2409FD777dA3B88AA446B4f8F34";
// 0x12A77096C9138911Aa839b1D9Bc1AEf500534Ba2
export const masterwallect = "0x81fdb269270ABD65C0fc09E82BcC09A02Fa102eF";



export const cardTypeImages = {
  visa: "https://logowik.com/content/uploads/images/visa-payment-card1873.jpg",
  // vise:vise,
  mastercard: 'https://lwcdn.freebitco.in/wp-content/uploads/2023/07/Mastercard-img.png',
  amex: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  discover: 'https://yt3.googleusercontent.com/ytc/AIf8zZSmZwIYzTVMMsZggpKALnzzyS28aowwtRed5_qjOQ=s900-c-k-c0x00ffffff-no-rj',
  diners: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  jcb: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  unionpay: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  maestro: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  mir: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  elo: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  hiper: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
  hipercard: 'https://as2.ftcdn.net/v2/jpg/03/07/14/09/1000_F_307140983_MDNd4Mtv5qgd3LAUK40ru1EPyYWL4elG.jpg',
};

export const clientId = "f42251107d7ce9c2c1147db0c4f041c8";
 export const clientSecret = "addacbc76a541f79e77ac6390dd4f1a3";
 export const customChain = {
  chainId: 137, // Chain ID of Polygon Mainnet
  rpc: ["https://polygon-rpc.com/"], 
  explorers: [{
    name: "polygonscan",
    url: "https://polygonscan.com/",
    standard: "EIP3091",
  }],
  nativeCurrency: {
    decimals: 18,
    name: "MATIC",
    symbol: "MATIC",
  },
  shortName: "matic", 
  slug: "polygon", 
  testnet: false, 
  chain: "Polygon", 
  name: "Polygon Mainnet",
};


// Currency symbol
// POL
// Network URL
// https://polygon-mainnet.infura.io
// Chain ID
// 137
// Network name
// Polygon Mainnet
// Block explorer URL
// https://polygonscan.com/

 export const polygonTokens = [
  {
    chainId: 137,
    address: '0x12A77096C9138911Aa839b1D9Bc1AEf500534Ba2', // 1CM Token
    name: '1CM Token',
    symbol: '1CM',
    decimals: 18,
    logoURI: 'https://example.com/1cm-logo.png', // Replace with actual image URL
  },
  {
    chainId: 137,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // Test USDT
    name: '(PoS) Tether USD',
    symbol: 'USDT',
    decimals: 6,
    logoURI: 'https://polygonscan.com/token/images/tether_32.png', // Replace with actual image URL
  },
];

 export const onemint=60000
 export const DocFile = "whitepaper.pdf";

 export const API_URLS = {
  
  SEND_NOTIFICATION: "https://staging-admin-onecm.ammag.tech/api/Nft/SendNotificationByGlUserID",

  // SEND_NOTIFICATION: "https://onecm-apis.onecannabismarketinc.com/api/Nft/SendNotificationByGlUserID",
};

