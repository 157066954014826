import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Container, Dropdown, Form, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faAngleDown,
  faCopy,
  faCheckCircle,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import setAuthorizationToken, {
  instance,
  masterwallect,
  onemint,
  tokenContractAddress,
  wallletaddressregex,
} from "../Constant/Constant";
import { useContextDetails } from "../Constant/AppContext";
import { NotificationTypes, showNotification } from "../Constant/Alert";
import { PulseLoader } from "react-spinners";
import Web3 from "web3";
import { tokenContractAbi } from "../Web3/TokenContractAbi";
import coin from "../Assets/image/coin-1.png";
import coin1 from "../Assets/image/coin-2.png";
import {
  addTokenToMetamask,
  ChainIds,
  formatAmount,
  getTokenBalance,
} from "../Web3/Constant";
import NavBar from "../Components/NavBar";
import { useAddress, useChainId } from "@thirdweb-dev/react";
import { useRef } from "react";
import {
  fetchTransactions,
  postOffRampTransaction,
  sendNotificationByGlUserID,
} from "../Constant/ApiCalling";
import Loader from "./Loader";
import moment from "moment";
import Pagination from "../Components/Pagination";

function CoinSwap() {
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("Offline");
  // const [walletAddress, setWalletAddress] = useState();
  const [isWalletAddress, setisWalletAddress] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSwap, setIsSwap] = useState(false);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [balance, setBalance] = useState();
  const [txHash, setTxHash] = useState(null);
  const [walletAddress, setWalletAddress] = useState(
    paymentMethod === "Offline" ? "" : masterwallect
  );
  const [isUserAddressDisabled, setIsUserAddressDisabled] = useState(false);
  const [tokensBalance, settokensBalance] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const contextData = useContextDetails();
  const [totalItemspage, setTotalItemspage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const address = useAddress();
  const chainId = useChainId();
  const getValidatorsfunction = async (address) => {
    if (!address || !chainId || chainId !== ChainIds) {
      return;
    }

    await getTokenBalance(tokenContractAddress, address)
      .then((fee) => {
        settokensBalance(fee / 10 ** 18);
      })
      .catch((err) => {
        // setAmount('');
      });
  };
  useEffect(() => {
    if (!address || !chainId || chainId !== ChainIds) {
      setAmount("");
      settokensBalance("");
    }
    getValidatorsfunction(address);
  }, [address, chainId]);
  useEffect(() => {
    if (!tokensBalance) {
      setAmount("");
    }
  }, [tokensBalance]);
  useEffect(() => {
    if (window.location.pathname === "/CoinSwap") {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener("popstate", function () {
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  useEffect(() => {
    contextData?.setonlinebalace(tokensBalance);
  }, [tokensBalance]);

  const balancelimit = balance;

  const handlePaymentMethodChange = (selectedMethod) => {
    setPaymentMethod(selectedMethod);
    setAmount("");

    if (selectedMethod === PaymentMethod.Online) {
      setWalletAddress(masterwallect);
      setIsUserAddressDisabled(true);
      setAmount("");
      if (!address) {
        showNotification("Please connect your wallet", NotificationTypes.ERROR);
      }
    } else {
      setWalletAddress("");
      setIsUserAddressDisabled(false);
    }
  };

  const handleAmountChange = (event) => {
    let input = event.target.value?.trim();
    // Allow only numeric characters (no decimals)
    input = input.replace(/[^0-9]/g, "");

    const isWithinLimit = !isNaN(input) && input.length <= 9;

    if (paymentMethod === PaymentMethod.Online) {
      if (tokensBalance === 0) {
        // showNotification('Token balance is zero', NotificationTypes.ERROR);
        return;
      }
      const isWithinTokenBalance = parseInt(input, 10) <= tokensBalance;

      if (!isWithinTokenBalance && input !== "") {
        // showNotification('Entered amount exceeds token balance', NotificationTypes.ERROR);
        return;
      }
    } else if (paymentMethod === PaymentMethod.Offline) {
      const isWithinOfflineBalanceLimit = parseInt(input, 10) <= balancelimit;

      if (!isWithinOfflineBalanceLimit && input !== "") {
        // showNotification('Entered amount exceeds offline balance limit', NotificationTypes.ERROR);
        return;
      }
    }
    if (isWithinLimit || input === "") {
      setAmount(input);
      setIsSwap(!!input.trim());
    } else {
      event.target.value = "";
    }
  };

  const handleChange = (event) => {
    let inputValue = event.target.value;

    if (!inputValue.startsWith("0x")) {
      inputValue = "" + inputValue.trim();
    }

    if (inputValue.length > 42) {
      inputValue = inputValue.slice(0, 42);
    }
    setWalletAddress(inputValue);
    const isValid = validateWalletAddress(inputValue);
    setisWalletAddress(isValid);
  };

  const validateWalletAddress = (address) => {
    const isValid = wallletaddressregex.test(address);
    return isValid;
  };

  const handleEnterAmount = (e) => {
    e.preventDefault();
    if (paymentMethod ===PaymentMethod.Online && !address) {
      showNotification("Please connect your wallet", NotificationTypes.ERROR);
      setIsLoading(false);
      return;
    }
    if (paymentMethod === PaymentMethod.Online && chainId != ChainIds) {
      showNotification(
        "Please switch network to the correct chain ",
        NotificationTypes.ERROR
      );
      setIsLoading(false);
      return;
    }
    if (paymentMethod === PaymentMethod.Online && !tokensBalance) {
      showNotification("Account balance insufficient", NotificationTypes.ERROR);
      setIsLoading(false);
      return;
    }

    if (!amount || !walletAddress) {
      showNotification(
        "Please fill in all required fields",
        NotificationTypes.ERROR
      );
      return;
    }
    if (parseFloat(amount) === 0) {
      showNotification("Amount cannot be zero", NotificationTypes.ERROR);
      return;
    }

    if (paymentMethod === PaymentMethod.Offline && !isWalletAddress) {
      showNotification("Invalid wallet address", NotificationTypes.ERROR);
      return;
    }

    setIsLoading(true);

    if (paymentMethod === PaymentMethod.Offline) {
      handleOfflineSwap();
    } else if (paymentMethod === PaymentMethod.Online) {
      handleOnlineSwap();
    }
  };
  let intervalId;
  const updateBalance = () => {
    const delayedFunctionCall = () => {
      if (!address || !chainId || chainId !== ChainIds) {
        return;
      }
      getTokenBalance(tokenContractAddress, address)
        .then((fee) => {
          let _balance = fee / 10 ** 18;
          if (tokensBalance == _balance) {
            return;
          }
          settokensBalance(_balance);
          clearInterval(intervalId);
        })
        .catch(() => {});
    };

    const intervalTime = 2000;
    intervalId = setInterval(delayedFunctionCall, intervalTime);

    return intervalId;
  };
  const handleOfflineSwap = () => {
    // lastSwapTime.current = currentTime; //
    const currentTime = Date.now();
    // Get last swap time from sessionStorage
    const lastSwapTime = sessionStorage.getItem("lastSwapTime") || 0;

    // Check if 1 minute (60000ms) has passed since the last API call
    if (currentTime - lastSwapTime < onemint) {
      // If the time difference is less than 1 minute, show error notification
      showNotification(
        "Please wait a minute before making another swap.",
        NotificationTypes.ERROR
      );
      setIsLoading(false);
      return;
    }

    const requestData = {
      accountId: contextData?.userInfo?.accountId,
      offerredAmount: amount,
      userAddress: walletAddress,
    };
    // const formData = new FormData();
    // for (const key in requestData) {
    //     formData.append(key, requestData[key]);
    // }

    setAuthorizationToken();

    instance
      .post("api/Swap/OffRampSwapV1", requestData)
      .then(async (response) => {
        showNotification(
          "You will receive your amount shortly",
          NotificationTypes.SUCCESS
        );
        updateBalance();
        setAmount("");
        getValidatorsfunction(address);
        setWalletAddress("");
        setIsSwap(false);
        fetchData();
        sessionStorage.setItem("lastSwapTime", Date.now());
        const title = "OffRamp Swap Completed";
        const descriptionMessage = `Your OffRamp Swap of ${amount} has been completed successfully.`;
        await sendNotificationByGlUserID(
          title,
          contextData?.userInfo?.gluserId,
          descriptionMessage,
          "swap_notification"
        );
      })
      .catch((error) => {
        showNotification(
          error?.response?.data?.message,
          NotificationTypes.ERROR
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleOnlineSwap = async () => {
    if (paymentMethod === PaymentMethod.Online&& !address) {
      showNotification("Please connect your wallet", NotificationTypes.ERROR);
      setIsLoading(false);
      return;
    }
    if (!address || !tokensBalance) {
      showNotification("Account balance insufficient", NotificationTypes.ERROR);
      setIsLoading(false);
      setAmount("");
      return;
    }
    setIsLoading(true);

    try {
      const web3 = new Web3(window.ethereum);
      const nonce = await web3.eth.getTransactionCount(address, "latest");
      const gasPrice = await web3.eth.getGasPrice();
      const increasedGasPrice = web3.utils
        .toBN(gasPrice)
        .mul(web3.utils.toBN(120))
        .div(web3.utils.toBN(100)); // Increase by 20%

      const tokenContract = new web3.eth.Contract(tokenContractAbi, tokenContractAddress);
      if (!tokenContractAbi || !tokenContractAddress) {
        showNotification("Token contract details are missing", NotificationTypes.ERROR);
        setIsLoading(false);
        return;
      }
  
      if (!tokenContract.methods.transfer) {
        showNotification("Transfer function is missing in contract", NotificationTypes.ERROR);
        setIsLoading(false);
        return;
      }
    
      const amountInWei = web3.utils.toWei(amount, "ether");
      const gasLimit = await tokenContract.methods
        .transfer(walletAddress, amountInWei)
        .estimateGas({ from: address });
    
      // **Send Transaction & Capture TX Hash Immediately**
      const transaction = tokenContract.methods.transfer(walletAddress, amountInWei).send({
        from: address,
        gas: gasLimit,
        gasPrice: increasedGasPrice.toString(),
      });
      transaction.on("transactionHash", async (txHash) => {
        setTxHash(txHash);
        sessionStorage.setItem("txHash", txHash);
        sessionStorage.setItem("offerredAmount", amount);
        sessionStorage.setItem("userAddress", address);
  
        const requestData = {
          transactionsHash: txHash,
          userAdress: address,
          amount: Number(amount),
        };
        try {
          await postOffRampTransaction(requestData);
          showNotification("You will receive your amount shortly", NotificationTypes.SUCCESS);
          getValidatorsfunction(address);
          setAmount("");
          setIsSwap(false);
          fetchData();
          updateBalance();
  
          const title = "OnRamp Swap Completed";
          const descriptionMessage = `Your OnRamp Swap of ${amount} has been completed successfully.`;
          await sendNotificationByGlUserID(
            title,
            contextData?.userInfo?.gluserId,
            descriptionMessage,
            "swap_notification"
          );
  
        } catch (error) {
        } finally {
          setIsLoading(false); 
        }
      });
  
      // **Handle User Rejection**
      transaction.on("error", (error) => {
        if (error?.code === 4001 || error?.message?.includes("User rejected the request")) {
          showNotification("Transaction rejected by the user.", NotificationTypes.ERROR);
        } else {
          showNotification("Transaction failed. Please try again.", NotificationTypes.ERROR);
        }
        setIsLoading(false);
        setIsSwap(false);
      });
  
    } catch (error) {
      if (error?.code === 4001 || error?.message?.includes("User rejected the request")) {
        showNotification("Transaction has been rejected.", NotificationTypes.ERROR);
      } else {
        console.error("Error interacting with MetaMask:", error);
        showNotification("Transaction is pending. You will receive your coins shortly.", NotificationTypes.INFO);
        sessionStorage.removeItem("txHash");
        sessionStorage.removeItem("offerredAmount");
        sessionStorage.removeItem("userAddress");
      }
      setIsSwap(false);
      setAmount("");
    }
  };
  

  useEffect(() => {
    const fetchDataFromSessionStorage = async () => {
      const storedTxHash = sessionStorage.getItem("txHash");
      const storedOfferedAmount = sessionStorage.getItem("offerredAmount");
      const storedUserAddress = sessionStorage.getItem("userAddress");

      if (storedTxHash && storedOfferedAmount && storedUserAddress) {
        try {
          setIsLoading(true);

          const requestData = {
            transactionsHash: storedTxHash,
            userAdress: storedUserAddress,
            amount: Number(storedOfferedAmount),
          };
      
          setAuthorizationToken();
          await  postOffRampTransaction(requestData);
          sessionStorage.removeItem("txHash");
          sessionStorage.removeItem("offerredAmount");
          sessionStorage.removeItem("userAddress");

          showNotification(
            "You will receive your amount shortly",
            NotificationTypes.SUCCESS
          );
          setAmount("");
          setIsSwap(false);
          fetchData();
          getValidatorsfunction(address);
          const title = "OnRamp Swap Completed";
          const descriptionMessage = `Your OnRamp Swap of ${storedOfferedAmount} has been completed successfully.`;
          await sendNotificationByGlUserID(
            title,
            contextData?.userInfo?.gluserId,
            descriptionMessage,
            "swap_notification"
          );
        } catch (error) {
          sessionStorage.removeItem("txHash");
          sessionStorage.removeItem("offerredAmount");
          sessionStorage.removeItem("userAddress");
          setAmount("");
        } finally {
          setIsLoading(false);
        }
      } else {
        // console.log('Required information not found in session storage');
      }
    };

    fetchDataFromSessionStorage();
  }, []);

  const fetchData = () => {
    setAuthorizationToken();
    instance
      .get("/api/Admin/GetWalletBalance")
      .then((response) => {
        const parsedBalance = parseFloat(response?.data?.data);
        const roundedBalance = Math.round(parsedBalance);
        contextData?.setIsBalance(roundedBalance);
        setBalance(roundedBalance);
      })
      .catch((error) => {
        // Handle API errors here.
      });
  };

  useEffect(() => {
    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(tokenContractAddress);

      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    } catch (error) {
      showNotification("Failed to copy to clipboard", NotificationTypes.ERROR);
    }
  };

  const PaymentMethod = {
    Online: "Online",
    Offline: "Offline",
  };

  const getTransactions = async () => {
    setLoading(true);
    try {
      const resp = await fetchTransactions(10, currentPage); // pass pageSize and currentPage
      setTransactions(resp.data || []);
      setTotalItemspage(resp.totalItems || 0);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setTotalItemspage(0);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    getTransactions();
  }, [currentPage]);
  

  /**
   * Copies the provided text to the clipboard and shows a notification.
   *
   * @param {string} text - The text to be copied.
   * @param {string} label - A label to display in the notification (e.g., "Transaction Hash", "User Address").
   */
  const handleCopy = async (text, label) => {
    if (!text) return;
    try {
      await navigator.clipboard.writeText(text);
      showNotification(
        `${label} copied to clipboard!`,
        NotificationTypes.SUCCESS
      );
    } catch (error) {
      showNotification(`Failed to copy ${label}!`, NotificationTypes.ERROR);
    }
  };

  return (
    <>
      {/* <NavBar  setAmount={setAmount}/> */}
      <main className="inner-main-faqs-coin">
        <div className="full-div">
          <div className="card-panel">
            <div className="flex-div">
              <h6 className="m-0">Swap</h6>
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  {paymentMethod} <FontAwesomeIcon icon={faAngleDown} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {paymentMethod === "Online" ? (
                    <Dropdown.Item
                      onClick={() => handlePaymentMethodChange("Offline")}
                    >
                      Offline
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item
                      onClick={() => handlePaymentMethodChange("Online")}
                    >
                      Online
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="spacer-20"></div>
            <div>
              <div className="your-amount-pnl">
                <Form>
                  <div className="amount-pnl">
                    <p>User Address</p>
                    <input
                      type="text"
                      className="walletaddress"
                      name="text"
                      value={walletAddress}
                      placeholder="Enter wallet address"
                      onChange={handleChange}
                      isInvalid={!isWalletAddress}
                      disabled={isUserAddressDisabled}
                      autoComplete="off"
                      
                      
                    />
                    {paymentMethod === "Offline" &&
                      !isWalletAddress &&
                      walletAddress && (
                        <div className="invalidwallet">
                          Invalid wallet address
                        </div>
                      )}
                  </div>
                  <div className="amount-pnl">
                    <div className="flex-divtext">
                      <p>
                        You Pay{" "}
                        {paymentMethod === PaymentMethod?.Online
                          ? PaymentMethod?.Online
                          : PaymentMethod?.Offline}
                        {paymentMethod === PaymentMethod?.Online &&
                          address &&
                          chainId == ChainIds && (
                            <FontAwesomeIcon
                              icon={faWallet}
                              className="copybtn"
                              onClick={addTokenToMetamask}
                            />
                          )}
                      </p>
                      <div className="small-img-pnl">
                        <img src={coin1} alt="coin" />
                      </div>
                    </div>

                    <div className="flex-div">
                      <input
                        type="text"
                        value={amount}
                        onChange={handleAmountChange}
                        className="inputfiled"
                        placeholder="0"
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                      />

                      <div className="small-img-pnl">
                        <span>
                          {formatAmount(
                            paymentMethod === "Online" ? tokensBalance : balance
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="amount-pnl">
                    <div className="flex-divtext">
                      <p>
                        You Receive{" "}
                        {paymentMethod === PaymentMethod?.Online
                          ? PaymentMethod?.Offline
                          : PaymentMethod?.Online}
                        {paymentMethod === PaymentMethod?.Offline &&
                          address &&
                          chainId == ChainIds && (
                            <FontAwesomeIcon
                              icon={faWallet}
                              className="copybtn"
                              onClick={addTokenToMetamask}
                            />
                          )}
                      </p>
                      <div className="small-img-pnl">
                        <img src={coin1} alt="coin" />
                      </div>
                    </div>
                    <div className="flex-div">
                      <input
                        type="text"
                        value={amount}
                        className="inputfiledamount"
                        placeholder="0"
                        autoComplete="off"
                        onPaste={(e) => e.preventDefault()}
                      />
                      <div className="small-img-pnl">
                        <span>
                          {formatAmount(
                            paymentMethod === PaymentMethod.Online ? balance : tokensBalance
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <Button
                    type="submit"
                    className="grey-btn"
                    onClick={handleEnterAmount}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <PulseLoader size={10} color="#fff" />
                    ) : isSwap ? (
                      "Swap"
                    ) : (
                      "Enter an amount"
                    )}
                  </Button>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <div className="full-div-tabel">
          <div className="card-tabel">
            <Container>
              <h4>Latest Transactions</h4>

              <Table bordered responsive>
                <thead>
                  <tr>
                    <th>Transaction Hash</th>

                    <th>Amount</th>
                    <th>User Address</th>
                    <th>From Currency</th>
                    <th>To Currency</th>
                    <th>Completed At</th>
                  </tr>
                </thead>
                <tbody>
  {loading ? (
    <tr className="h-16">
      <td colSpan="8" className="text-center py-4 align-middle">
        <Loader animation="border" />
      </td>
    </tr>
  ) : transactions?.length > 0 ? (
    transactions?.map((txn, index) => (
      <tr key={index} className="h-10 align-middle">
        <td className="copyable-text">
          {txn.transactionHash.slice(0, 10)}...{txn.transactionHash.slice(-10)}
          <FontAwesomeIcon
            icon={faCopy}
            className="copy"
            onClick={() =>
              handleCopy(txn.transactionHash, "Transaction Hash")
            }
          />
        </td>

        <td>{txn.amount}</td>
        <td className="copyable-text">
          {txn.userAddress.slice(0, 10)}...{txn.userAddress.slice(-10)}
          <FontAwesomeIcon
            icon={faCopy}
            className="copy"
            onClick={() => handleCopy(txn.userAddress, "User Address")}
          />
        </td>
        <td>{txn.fromCurrency}</td>
        <td>{txn.toCurrency}</td>

        <td>
          {moment.utc(txn.completedAt).local().format("YYYY-MM-DD hh:mm A")}
        </td>
      </tr>
    ))
  ) : (
    <tr className="h-16">
      <td colSpan="8" className="text-center align-middle">
        No transactions found
      </td>
    </tr>
  )}
</tbody>

              </Table>
              <Pagination
   currentPage={currentPage}
  itemsPerPage={10}
  totalItems={totalItemspage}
  onPageChange={(pageNumber) => {
    setCurrentPage(pageNumber);
  }}
/>
            </Container>
          </div>
        </div>
      </main>
    </>
  );
}

export default CoinSwap;
